declare var woocommerce_params;
export class product_gallery {
    constructor() {
        this.Init();
    }
    gallery;
    ajaxURL;
    Init() {
        // this.slickSetup();
        this.ajaxURL = woocommerce_params['ajax_url'];
        this.gallery = new gallery();
        this.setupVariableImages();
    }

    // slickSetup() {
    //     console.log(this.element);
    //     this.element.slick({
    //         dots: false,
    //         infinite: true,
    //         speed: 300,
    //         slidesToScroll: 1,
    //         slidesToShow: 1,
    //         lazyLoad: 'ondemand',
    //         // mobileFirst: true,

    //     });
    // }

    setupVariableImages() {
        let _this = this;
        jQuery("form.cart.variations_form").on("show_variation", function () {

            let data = {
                action: 'load_variable_thumbnail',
                product_id: jQuery(this).find('input[name="product_id"]').val(),
                variation_id: jQuery(this).find('input[name="variation_id"]').val()
            }

            jQuery.ajax({
                method: 'POST',
                url: _this.ajaxURL,
                data: data,
                beforeSend: function (response) {
                    _this.gallery.gallery.addClass('swapping_images');
                },
                complete: function (response) {
                    _this.gallery.gallery.removeClass('swapping_images');
                },
                success: (response) => {
                    jQuery.each(response, function (key: any, value: any) {
                        let toReplace = jQuery(key);
                        let replaceVal = jQuery(value);

                        toReplace.attr('data-thumb', replaceVal.attr('data-thumb'));
                        toReplace.attr('data-thumb-alt', replaceVal.attr('data-thumb-alt'));
                        toReplace.html(replaceVal.html());

                        //Reset gallery to first image
                        _this.gallery.thumbnails.find('.thumbnail_icons').slick("slickGoTo", 0);
                        _this.gallery.thumbnails.find('.thumbnail_icons .slick-current a').trigger('click');
                    });
                }
            });
        });



    }


}

class gallery {
    gallery;
    constructor() {
        this.gallery = jQuery('.product-gallery');
        if (this.gallery.length) {
            this.init();//Only init if gallery exists
        }
    }

    thumbnails;
    mainImage;

    init() {
        this.thumbnails = this.gallery.find('.thumbnails');
        this.mainImage = this.gallery.find('.main_image');
        this.setupThumbnails();
        this.setupMainImage();
    }

    setupThumbnails() {
        let _this = this;
        this.thumbnails.find('.thumbnail_icons').slick({
            slidesToShow: 5,
            swipeToSlide: true,
            slidesToScroll: 1,
            draggable: true,
            infinite: true,
            prevArrow: '<span class="arrow_container prev"><i class="fas fa-sort-up"></i></span>',
            nextArrow: '<span class="arrow_container next"><i class="fas fa-sort-down"></i></span>',
            vertical: false,
            verticalSwiping: false,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    vertical: false,
                    verticalSwiping: false,
                    slidesToShow: 3,
                }
            }]
        });
        this.slickVerticalSlideFix();//Temp vertical scroll fix
        this.thumbnails.on('click', 'a', function (event) {
            event.preventDefault();
            _this.setMainImage(this.getAttribute('href'));
        })

        let share_button = this.thumbnails.find('.share_button');
        if (share_button.length) {
            let delay = 0.1;
            jQuery('.share_popup .share_container .addtoany_shortcode a').each(function () {
                this.style.transitionDelay = delay + 's';
                delay += 0.1;
            });
            jQuery('.share_popup .share_title').css('transition-delay', (delay + 0.1) + 's');
            share_button.on('click', function () {
                jQuery('.share_popup').addClass('active');
            });

            jQuery('.share_popup').on('click', function (e) {
                if (e.target.classList.contains('share_popup')) {
                    this.classList.remove('active');
                }
            });
        }
    }

    setupMainImage() {
        //@ts-ignore
        lightGallery(this.mainImage[0], {
            selector: '.woocommerce-product-gallery__image',
            //@ts-ignore
            plugins: [lgZoom, lgThumbnail],
        });
        this.setupImageZoom();
    }

    setupImageZoom() {

        let imageMove = false;

        this.mainImage.find('.woocommerce-product-gallery__image').on('mousemove', function (event) {
            if (imageMove) {
                let offset = jQuery(this).offset();
                var xPos = (event as any).pageX - offset.left;
                var yPos = (event as any).pageY - offset.top;

                let center = [jQuery(this).width() / 2, jQuery(this).height() / 2];
                let mousePos = [xPos - center[0], yPos - center[1]];

                let percent = [(35 / jQuery(this).width()) * mousePos[0], (35 / jQuery(this).height()) * mousePos[1]]

                let translate = 'translate(' + (0 - percent[0]) + '%, ' + (0 - percent[1]) + '%)';

                jQuery(this).find('img').css('transform', translate);
            }
        });

        this.mainImage.find('.woocommerce-product-gallery__image').on('mouseenter', function (event) {
            setTimeout(function () {
                imageMove = true;
            }, 300);
        });

        this.mainImage.find('.woocommerce-product-gallery__image').on('mouseout', function (event) {
            imageMove = false;
            jQuery(this).find('img').css('transform', '');
        });
    }

    setMainImage(image) {
        this.mainImage.find('.woocommerce-product-gallery__image.show').removeClass('show');
        this.mainImage.find('.woocommerce-product-gallery__image a[href="' + image + '"]').parent().addClass('show');
    }



    slickVerticalSlideFix() {
        //Temp Slick fix - swipeToSlide doesn't work vertically
        //TODO Check for slick updates
        this.thumbnails.find('.thumbnail_icons').each(function () {
            this.slick.getSlideCount = function () {

                var _ = this,
                    slidesTraversed, swipedSlide, centerOffset;


                centerOffset = _.options.centerMode === true ? _.slideWidth * Math.floor(_.options.slidesToShow / 2) : 0;

                if (_.options.swipeToSlide === true) {

                    _.$slideTrack.find('.slick-slide').each(function (index, slide) {
                        var offsetPoint = slide.offsetLeft,
                            outerSize = jQuery(slide).outerWidth();

                        if (_.options.vertical === true) {
                            offsetPoint = slide.offsetTop;
                            outerSize = jQuery(slide).outerHeight();
                        }
                        if (offsetPoint - centerOffset + (outerSize / 2) > (_.swipeLeft * -1)) {
                            swipedSlide = slide;
                            return false;
                        }
                    });
                    //@ts-ignore
                    slidesTraversed = Math.abs(jQuery(swipedSlide).attr('data-slick-index') - _.currentSlide) || 1;

                    return slidesTraversed;
                } else {
                    return _.options.slidesToScroll;
                }

            };

            this.slick.getNavigableIndexes = function () {

                var _ = this,
                    breakPoint = 0,
                    counter = 0,
                    indexes = [],
                    max;

                if (_.options.infinite === false) {
                    max = _.slideCount;
                } else {
                    breakPoint = _.options.slideCount * -1;
                    counter = _.options.slideCount * -1;
                    max = _.slideCount * 2;
                }

                while (breakPoint < max) {
                    indexes.push(breakPoint);
                    breakPoint = counter + _.options.slidesToScroll;
                    counter += _.options.slidesToScroll <= _.options.slidesToShow ? _.options.slidesToScroll : _.options.slidesToShow;
                }

                return indexes;

            };
        });

    }
}