

export class product_variations {
    constructor() {
        this.Init();
    }

    Init() {
        this.setupVariations();
    }


    setupVariations() {
        jQuery(".add_to_cart_btn").on("click", function () {
            event.preventDefault();
            jQuery(".single_add_to_cart_button").click();
        });

        jQuery("form.cart.variations_form").on("show_variation", function () {
            jQuery(".add_to_cart_btn").removeClass("btn_disabled");
            jQuery(".paypal_buttons_container").removeClass("paypal_disabled");
        });
        jQuery("form.cart.variations_form").on("reset_data", function () {
            jQuery(".wishlist_disabled").show();
            jQuery(".wishlist-toggle-btn").hide();
            jQuery(".add_to_cart_btn").addClass("btn_disabled");
            jQuery(".paypal_buttons_container").addClass("paypal_disabled");
        });

        jQuery('.variations_form select').on('change', function () {
            console.log("loaded");
            let variation_id = jQuery(this).attr('id');
            if (!((this as HTMLSelectElement).value == "")) {
                jQuery('#attribute_value_' + variation_id).html((this as HTMLSelectElement).options[(this as HTMLSelectElement).selectedIndex].text);
            } else {
                jQuery('#attribute_value_' + variation_id).html("Choose an option");
            }
        }).trigger('change');

    }
}