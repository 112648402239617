export class header {
    constructor() {
        this.setupMobileMenu();

        jQuery(document).on("mouseup", function (e) {
            var container = jQuery("#menu-main-menu");
            //@ts-ignore
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                jQuery('#navicon').removeClass('open');
                jQuery('body').removeClass('show-nav');
                jQuery('.mobile-nav .expanded').removeClass('expanded');
            }
        });

        let siteHeader = jQuery('.site-header');

        jQuery('.search_toggle').on('click', function () {
            console.log("test");
            if (jQuery(window).width() <= 768) {
                if (jQuery('.aws-container.mobile input').val() != '' && jQuery('.aws-container.mobile').hasClass('display')) {
                    jQuery('.aws-container.mobile .aws-search-form').trigger("submit");
                } else {
                    jQuery('.aws-container.mobile').toggleClass('display');
                    if (jQuery('.aws-container.mobile').hasClass('display')) {
                        jQuery('.aws-container.mobile input').focus();
                    }
                }
            } else {
                if (jQuery(this.parentElement).find('.aws-container input').val() != '') {
                    jQuery(this.parentElement).find('.aws-search-form').trigger("submit");
                } else {
                    jQuery(this.parentElement).toggleClass('display');
                    if (jQuery(this.parentElement).hasClass('display')) {
                        jQuery(this.parentElement).find('.aws-container:not(.mobile) input').focus();
                    }
                }
            }
        });

        jQuery(".upper li").on("click", function () {
            var location_href = jQuery(this).find("a").attr("href");
            jQuery(location).prop('href', location_href)
            return false;
        });


    }

    headerHeight;

    mobileMenu;

    setupMobileMenu() {
        //Mobile Menu
        jQuery('.mobile-nav .menu-item.menu-item-has-children').on('click', function () {
            jQuery(this).addClass('expanded');
        });

        jQuery('.mobile-nav .menu-item.menu-item-has-children').each(function () {
            let returnItem = document.createElement('li');
            returnItem.className = 'return-item menu-item';
            returnItem.innerHTML = this.getElementsByTagName('a')[0].outerHTML;
            this.getElementsByClassName('sub-menu')[0].prepend(returnItem);
        });

        jQuery('.mobile-nav').on('click', '.menu-item.return-item', function () {
            jQuery(this).closest('.menu-item-has-children.expanded').removeClass('expanded');
        });

        // jQuery("body").on('scroll', function () {
        //     jQuery('#navicon').removeClass('open');
        //     jQuery('body').removeClass('show-nav');
        //     jQuery('.mobile-nav .expanded').removeClass('expanded');
        // });
        jQuery('#navicon').on('click', function () {

            if (this.classList.contains('open')) {
                jQuery('.mobile-nav .expanded').removeClass('expanded');
            }
        });
        jQuery('.close_mobile_nav').on('click', function () {
            jQuery('#navicon').removeClass('open');
            jQuery('body').removeClass('show-nav');
            jQuery('.mobile-nav .expanded').removeClass('expanded');
        });
    }

}