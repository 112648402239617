export class cart_page {

    constructor() {
        this.Init();
    }

    Init() {
        jQuery(".add_vat_to_customer ").on("click", function () {
            let data = {
                'action': 'add_vat_to_customer',
            };

            jQuery.post("/wp/wp-admin/admin-ajax.php", data, (response) => {
                response = response.trim();
                if (response == "vat added") {

                    setTimeout(function () {
                        window.location.reload();
                    }, 100);
                }
            });
        });

    }






}