export class case_studies_slider {

    constructor() {
        this.Init();
    }

    Init() {
        this.caseStudiesSlider();
    }

    caseStudiesSlider() {
        //@ts-ignore
        jQuery('.case_studies').slick({
            slidesToScroll: 1,
            infinite: true,
            arrows: false,
            dots: true,
            fade: true,
            cssEase: 'ease-in-out',
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 2000,

        });

    }


}