export class product_category {
    constructor() {
        this.init();
    }

    init() {
        this.setupSidebar();
        this.addListeners();
        this.productLink();
    }

    setupSidebar() {
        let sidebar = jQuery('.sidebar');
        if (sidebar.length) {
            let sidebarHeight = (sidebar.get() as any).scrollHeight;
            sidebar.css('--sidebar_height', sidebarHeight + 'px');
        }
    }

    addListeners() {
        jQuery('header.woocommerce-products-header .scroll_down').on('click', function (event) {
            event.preventDefault();
            jQuery('body,html').animate({
                scrollTop: jQuery('.filters_container').closest('#primary').offset().top
            });
        });

        //Toggle Filters
        jQuery('.sidebar .toggle_filters').on('click', function () {
            jQuery(this).closest('.sidebar').toggleClass('open');
        });
    }
    productLink() {
        jQuery('.category_product_image').on('click', function (e) {
            if (e.target.className != "far fa-heart") {
                var product_link = jQuery(this).data('product_link');
                window.location.href = product_link;

            }
        });
    }
}