export class featured_products {

    constructor() {
        this.Init();
    }

    Init() {
        this.featuredProducts();
    }

    featuredProducts() {
        //@ts-ignore
        jQuery('.featured-products').slick({
            slidesToScroll: 1,
            infinite: true,
            arrows: true,
            dots: false,
            slidesToShow: 4,
            responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 970,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 660,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        });

    }


}