import { header } from "./header";
import { product_gallery } from "./single_product/product_gallery"
import { product_variations } from "./single_product/product_variations"
import { WooCommerceAccountMenu } from "./woocommerce/account-menu"
import { product_category } from "./woocommerce/product-category"
import { cart_page } from "./woocommerce/cart"
import { featured_products } from "./shortcodes/featured-products";
import { case_studies_slider } from "./shortcodes/case_studies_slider";

jQuery(document).on('ready', () => {
    let headerSettings = new header;
    console.log("here");
});


jQuery(document).on('ready', () => {
    let caseStudySlider = new case_studies_slider;
    let featuredProducts = new featured_products;
    if (jQuery('body').hasClass('single-product')) {
        let productPage = new product_gallery;
        let productVariations = new product_variations;
    }
    // Account
    if (jQuery("body").hasClass("woocommerce-account")) {
        let wooCommerceAccountMenu = new WooCommerceAccountMenu();
    }
    if (jQuery("body").hasClass("woocommerce-cart") || jQuery("body").hasClass("single-product")) {
        let cartPage = new cart_page();
        jQuery(document).on('submit_success', function () {
            location.reload();
        });

    }
    if (jQuery('body').is('.woocommerce.archive')) {
        let productCategory = new product_category;
    }

});


