export class WooCommerceAccountMenu {

    constructor() {
        this.Init();
    }

    Init() {
        // jQuery(document).ready(() => {
        this.CreateMenuStructure();
        this.RegisterHandlers();
        // this.SetupDealershipSettings();
        // });

    }

    CreateMenuStructure() {
        jQuery(".woocommerce-MyAccount-navigation").append("<div class='account-menu-label'>Account Menu</div><div class='account-menu-toggle'><i class='fa fa-bars tog'></i><i class='fa fa-times tog' style='display:none;'></i></div>");
    }

    RegisterHandlers() {
        jQuery(document).on("click", ".account-menu-toggle", () => {
            this.ToggleMobileMenu();
        })
    }

    ToggleMobileMenu() {
        jQuery(".woocommerce-MyAccount-navigation").toggleClass("open");
        jQuery(".tog").toggle();
        jQuery(".account-menu-label").toggle();
    }




}